var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"download_student_fee_collection_report",attrs:{"method":"post"}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t("Fee Type"))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Class'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.fee_head_id),expression:"fee_head_id",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"name":"fee_head_id","id":"fee_head_id","state":errors.length > 0 ? false : null},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.fee_head_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getPaymentStatus()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("Select Type")]),_vm._l((_vm.student_fee_heads),function(head,key){return _c('option',{key:key,domProps:{"value":head.id}},[_vm._v(" "+_vm._s(head.name)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t("Flat"))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Class'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.version_id),expression:"version_id",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"name":"version_id","id":"version_id","state":errors.length > 0 ? false : null},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.version_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getPaymentStatus()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("Select Flat")]),_c('option',{domProps:{"value":null}},[_vm._v("All")]),_vm._l((_vm.versions),function(ver,key){return _c('option',{key:key,domProps:{"value":ver.id}},[_vm._v(" "+_vm._s(ver.name)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-card-text',[_c('span',[_vm._v("Report Type ")])]),_c('validation-provider',{attrs:{"name":"report_type_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.report_type_id),expression:"report_type_id"}],staticClass:"form-control",attrs:{"name":"report_type_id","id":"report_type_id","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.report_type_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getPaymentStatus()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("Select One")]),_c('option',{key:1,domProps:{"value":1}},[_vm._v("Monthly")]),_c('option',{key:2,domProps:{"value":2}},[_vm._v("Yearly")]),_c('option',{key:3,domProps:{"value":3}},[_vm._v("By Date Range")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.report_type_id === 1 || _vm.report_type_id === 2)?_c('b-col',{attrs:{"md":"3"}},[_c('b-card-text',[_c('span',[_vm._v("Target Year")])]),_c('validation-provider',{attrs:{"name":"academic_year_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.year_id),expression:"year_id"}],staticClass:"form-control",attrs:{"name":"academic_year_id","id":"academic_year_id","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.year_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getPaymentStatus()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("Select One")]),_vm._l((_vm.active_academic_years),function(ayear,key){return _c('option',{key:key,domProps:{"value":ayear.id}},[_vm._v(" "+_vm._s(ayear.year)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4200199235)})],1):_vm._e(),(_vm.report_type_id === 1)?_c('b-col',{attrs:{"md":"3"}},[_c('b-card-text',[_c('span',[_vm._v("Desired Month ")])]),_c('validation-provider',{attrs:{"name":"month_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.month_id),expression:"month_id"}],staticClass:"form-control",attrs:{"name":"month_id","id":"month_id","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.month_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getPaymentStatus()}]}},[_c('option',{domProps:{"value":null}},[_vm._v("Select One")]),_c('option',{key:_vm.key,domProps:{"value":1}},[_vm._v("January")]),_c('option',{key:_vm.key,domProps:{"value":2}},[_vm._v("February")]),_c('option',{key:_vm.key,domProps:{"value":3}},[_vm._v("March")]),_c('option',{key:_vm.key,domProps:{"value":4}},[_vm._v("April")]),_c('option',{key:_vm.key,domProps:{"value":5}},[_vm._v("May")]),_c('option',{key:_vm.key,domProps:{"value":6}},[_vm._v("June")]),_c('option',{key:_vm.key,domProps:{"value":7}},[_vm._v("July")]),_c('option',{key:_vm.key,domProps:{"value":8}},[_vm._v("August")]),_c('option',{key:_vm.key,domProps:{"value":9}},[_vm._v("September")]),_c('option',{key:_vm.key,domProps:{"value":10}},[_vm._v("Otcober")]),_c('option',{key:_vm.key,domProps:{"value":11}},[_vm._v("November")]),_c('option',{key:_vm.key,domProps:{"value":12}},[_vm._v("December")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,949396208)})],1):_vm._e(),(_vm.report_type_id === 3)?_c('b-col',{attrs:{"md":"3"}},[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t("Start Date"))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('report start date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"name":"rpt_start_date","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"date-format-options":{ year: 'numeric', month: 'numeric', day: '2-digit' },"placeholder":_vm.$t('Select start date')},model:{value:(_vm.rpt_start_date),callback:function ($$v) {_vm.rpt_start_date=$$v},expression:"rpt_start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2114384997)})],1):_vm._e(),(_vm.report_type_id === 3)?_c('b-col',{attrs:{"md":"3"}},[_c('b-card-text',[_c('span',[_vm._v(_vm._s(_vm.$t("End Date"))+" ")])]),_c('validation-provider',{attrs:{"name":_vm.$t('report end date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"name":"rpt_end_date","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"date-format-options":{ year: 'numeric', month: 'numeric', day: '2-digit' },"placeholder":_vm.$t('select end date')},model:{value:(_vm.rpt_end_date),callback:function ($$v) {_vm.rpt_end_date=$$v},expression:"rpt_end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2393931754)})],1):_vm._e(),_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.downloadTutionBill.apply(null, arguments)}}},[_vm._v(" Download Report ")])],1)],1),(_vm.paymentStatusList.length > 0)?_c('table',{attrs:{"border":"1px;","id":"student_bills_paid_info"}},[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("SL#"))+" ")]),_c('td',[_vm._v(_vm._s(_vm.$t("Student ID"))+" ")]),_c('td',{attrs:{"width":"200"}},[_vm._v(_vm._s(_vm.$t("Name"))+" ")]),_vm._l((_vm.getFeeTypeList),function(item,index){return _c('td',{key:index},[_vm._v(_vm._s(item.name))])}),_c('td',[_vm._v(_vm._s(_vm.$t("Status")))])],2),_vm._l((_vm.paymentStatusList),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item.sl_number)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.cadet_no)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.cadet_name)+" ")]),_vm._l((Object.entries(item.student_assigned_fee).map(function (ref) {
	var key = ref[0];
	var value = ref[1];

	return ({ key: key, value: value });
})),function(fitem,findex){return _c('td',{key:findex},[_vm._v(" "+_vm._s(fitem.value)+" ")])}),_c('td',[_vm._v(" "+_vm._s(item.paid_status)+" ")])],2)})],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }