<template>
    <b-card-code>

        <validation-observer ref="simpleRules">
            <b-form ref="download_student_fee_collection_report" method="post">
                <b-row>
                    <b-col md="3">
                    <b-form-group>
                    <b-card-text>
                        <span>{{  $t("Fee Type")  }} </span>
                    </b-card-text>
                    <validation-provider #default="{ errors }" :name="$t('Class')" rules="required">
                        <select v-model.number="fee_head_id" @change="getPaymentStatus()" class="form-control" name="fee_head_id" id="fee_head_id"
                        :state="errors.length > 0 ? false : null">
                        <option :value="null">Select Type</option>
                        <option v-for="(head, key) in student_fee_heads" :key="key" :value="head.id">
                            {{  head.name  }}
                        </option>
                        </select>
                        <small class="text-danger">{{  errors[0]  }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>

                    <b-col md="3">
                        <b-card-text>
                            <span>{{  $t("Flat")  }} </span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" :name="$t('Class')" rules="required">
                          <select v-model.number="version_id" @change="getPaymentStatus()" class="form-control" name="version_id" id="version_id"
                          :state="errors.length > 0 ? false : null">
                          <option :value="null">Select Flat</option>
                          <option :value="null">All</option>
                          <option v-for="(ver, key) in versions" :key="key" :value="ver.id">
                              {{  ver.name  }}
                          </option>
                          </select>
                          <small class="text-danger">{{  errors[0]  }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col md="3">
                        <b-card-text>
                            <span>Report Type </span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" name="report_type_id" rules="required">
                            <select name="report_type_id" id="report_type_id" v-model="report_type_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" @change="getPaymentStatus()" class="form-control">
                                <option :value="null">Select One</option>
                                <option :value="1" :key="1">Monthly</option>
                                <option :value="2" :key="2">Yearly</option>
                                <option :value="3" :key="3">By Date Range</option>
                            </select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>

                    <b-col md="3" v-if="report_type_id === 1 || report_type_id === 2">
                        <b-card-text>
                            <span>Target Year</span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" name="academic_year_id" rules="required">
                            <select name="academic_year_id" id="academic_year_id" v-model="year_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            @change="getPaymentStatus()" class="form-control">
                                <option :value="null">Select One</option>
                                <option v-for="(ayear, key) in active_academic_years" :value="ayear.id" :key="key">
                                    {{ ayear.year }}
                                </option>
                            </select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>

                    <b-col md="3" v-if="report_type_id === 1">
                        <b-card-text>
                            <span>Desired Month </span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" name="month_id" rules="required">
                            <select name="month_id" id="month_id" v-model="month_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            @change="getPaymentStatus()" class="form-control">
                                <option :value="null">Select One</option>
                                <option :value="1" :key="key">January</option>
                                <option :value="2" :key="key">February</option>
                                <option :value="3" :key="key">March</option>

                                <option :value="4" :key="key">April</option>
                                <option :value="5" :key="key">May</option>
                                <option :value="6" :key="key">June</option>

                                <option :value="7" :key="key">July</option>
                                <option :value="8" :key="key">August</option>
                                <option :value="9" :key="key">September</option>

                                <option :value="10" :key="key">Otcober</option>
                                <option :value="11" :key="key">November</option>
                                <option :value="12" :key="key">December</option>
                            </select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>

                    <b-col md="3" v-if="report_type_id === 3">
                        <b-card-text>
                            <span>{{ $t("Start Date") }} </span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" :name="$t('report start date')" rules="required">
                            <b-form-datepicker name="rpt_start_date" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit' }"
                                v-model="rpt_start_date" :placeholder="$t('Select start date')" class="form-control">
                            </b-form-datepicker>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>

                    <b-col md="3" v-if="report_type_id === 3">
                        <b-card-text>
                            <span>{{ $t("End Date") }} </span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" :name="$t('report end date')" rules="required">
                            <b-form-datepicker name="rpt_end_date" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit' }"
                                v-model="rpt_end_date" :placeholder="$t('select end date')" class="form-control">
                            </b-form-datepicker>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>

                    <!-- submit button -->
                    <b-col md="12" class="mt-2">
                        <b-button variant="primary" type="submit" @click.prevent="downloadTutionBill">
                            Download Report
                        </b-button>
                    </b-col>
                </b-row>

        <table border="1px;" id="student_bills_paid_info" v-if="paymentStatusList.length > 0">
            <tr>
                <td>{{  $t("SL#")  }} </td>
                <td>{{  $t("Student ID")  }} </td>
                <td width="200">{{  $t("Name")  }} </td>
                <td v-for="(item, index) in getFeeTypeList" :key="index">{{ item.name  }}</td>
                <td>{{ $t("Status") }}</td>
            </tr>
            <tr v-for="(item, index) in paymentStatusList" :key="index">
                <td>
                    {{item.sl_number}}
                </td>
                <td>
                {{ item.cadet_no }}
                </td>
                <td>
                {{ item.cadet_name }}
                </td>
                <td v-for="(fitem, findex) in Object.entries(item.student_assigned_fee).map(([key, value]) => ({ key, value }))" :key="findex">
                    {{fitem.value}}
                </td>
                <td>
                {{ item.paid_status }}
                </td>
            </tr>
            </table>
            </b-form>
        </validation-observer>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormDatepicker
} from "bootstrap-vue";
import { required, integer } from "@validations";
import apiCall from "@/libs/axios";
import download from "downloadjs";

export default {
    name: "reportCollection",
    components: {
        BCardCode,
        ValidationProvider,
        ValidationObserver,
        BFormDatepicker,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText
    },
    data() {
        return {
            classes_id: null,
            wing_id: null,
            rpt_start_date: "",
            rpt_end_date: "",
            required,
            integer,
            classes: [],
            paymentStatusList:[],
            wings: [],
            report_type_id: null,
            month_id: null,
            year_id: null,
            version_id: null,
            fee_head_id: null
        };
    },
    created() {
        this.getClassList();
        if (this.$store.getters.active_academic_years < 1) {
            this.$store.dispatch("GET_ACTIVE_ACADEMIC_YEAR");
        }
        if (this.versions.length < 1) this.$store.dispatch("GET_ALL_VERSION");
        if (this.student_fee_heads.length < 1) this.$store.dispatch('GET_ALL_STUDENT_FEE_HEAD');
        if (this.student_fee_types.length < 1) this.$store.dispatch('GET_ALL_STUDENT_FEE_TYPE');
    },
    methods: {
        getBillingAmount(fitem) {

            let billingValue = this.student_fee_types.find(
                (item) =>
                item.id === parseInt(fitem.key)

            );

            if (billingValue) {
                return billingValue.value;
            }
            else {
                return 0;
            }
            },
        getPaymentStatus(){
            let data = new FormData(this.$refs.download_student_fee_collection_report);
           
            if(document.getElementById('fee_head_id').value != "" && 
                document.getElementById('report_type_id').value != "" &&
                document.getElementById('academic_year_id').value != "" && 
                document.getElementById('month_id').value != ""){

                apiCall
                .post("/get/student/bill/collection/preview", data)
                .then((response) => {
               
                    this.paymentStatusList = response.data.data;
                })
                .catch((error) => {
                    this.$toaster.error(error.response.data.message);
                });
            }
        },
        downloadTutionBill() {
            let data = new FormData(this.$refs.download_student_fee_collection_report);

            apiCall
                .post("/download/student/tution/bill/collection/report", data, {
                    responseType: "blob",
                })
                .then((response) => {
                    const content = response.headers["content-type"];
                    download(response.data, "tution_and_messing_fee_report.pdf", content);
                })
                .catch((error) => {
                    this.$toaster.error(error.response.data.message);
                });
        },
        async getClassList() {
            await apiCall
                .get("get/active/class/with/v/s/groups")
                .then((response) => {
                    this.classes = response.data;
                    this.wings = response.data[0].wings;
                })
                .catch(() => {
                    this.classes = [];
                    this.wings = [];
                });
        },
    },
    computed: {
        ...mapGetters([
            "active_academic_years",
            'versions',
            'student_fee_heads',
            'student_fee_types'
        ]),

        getFeeTypeList() 
        {
            const feeTypeList = Object.keys(this.paymentStatusList[0].student_assigned_fee);
            const billList = this.student_fee_types.filter((feeHead) => {
            if (feeHead.student_fee_head_id === parseInt(this.fee_head_id))
                return feeHead;
            });

            const idsToFilter = billList.filter(item => feeTypeList.join(',').includes(item.id));
            return idsToFilter;
        }
    }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
